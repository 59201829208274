import React, { Component } from 'react';
import '../styling/App.css';
import data from '../data/story.json'

class App extends Component {
        constructor(props) {
        super(props)
        this.state = {
            nodes: null,
            selectedNode: null,
        };
    }

    componentDidMount() {
        // Load the data
        let nodes = Object.fromEntries(data["graph"].map(node => [node.id, node]))
        let starting_node = nodes[data["starting_node"]]
        console.log(nodes)
        console.log(starting_node);
        // console.log(nodes)
        this.setState({
            nodes: nodes,
            selectedNode: starting_node
        });
    }

    render() {
        // console.log(this.state.selectedNode)
        return (
            <div className="App">
                {this.state.selectedNode && this.state.selectedNode.image && <div className="Image">
                    <img src={"/assets/" + this.state.selectedNode.image} alt={"Very useful art"}/>
                </div>}
                <div className="Decision">
                    {this.state.selectedNode && this.state.selectedNode.content}
                </div>
                {this.state.selectedNode && this.state.selectedNode.edges && <div className="Choices">
                    {this.state.selectedNode && this.state.selectedNode.edges && this.state.selectedNode && this.state.selectedNode.edges.map((edge, idx) =>
                    <div className="Choice" key={idx} onClick={() => this.setState({selectedNode: this.state.nodes[edge.to_node]})}>
                        {edge.content}
                    </div>)}
                </div>}
            </div>
        );
    }

}

export default App;
